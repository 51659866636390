/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { graphql } from "gatsby";
import MediaQuery from "react-responsive";
import DetailItem from "../components/detail/detail-item";
import Layout from "../components/layout";
import { ArticleModel, ContentBlock } from "../models/ArticleModel";
import { Utils } from "../utils";

type DataProps = {
  data: {
    wpWorkDetail:ArticleModel;
    wpWork: ArticleModel;
  };
};

const WorkDetailDetail = ({ data }: DataProps) => {
  // console.log(data);

  const article = data.wpWorkDetail;
  const metaInfo = article.meta_info;
  let title: string | undefined;
  let description: string | undefined;
  if (process.env.GATSBY_LANG === "JP") {
    title = `${article.title} | PROJECT `;
    description = metaInfo.metaDescription.ja;
  } else if (process.env.GATSBY_LANG === "EN") {
    if (article.title_en && article.title_en.titleEn) {
      title = `${article.title_en.titleEn} | PROJECT `;
    } else {
      title = `${article.title} | PROJECT `;
    }
    description = metaInfo.metaDescription.en;
  }
  const ogImage = metaInfo.metaImage?.mediaItemUrl ?? undefined;
  const blocks = article.blocks.block ?? [];

  const pictureAll = Utils.getPictures(blocks);

  const createBlock = (
    block: ContentBlock,
    index: number,
    isLast?: boolean
  ) => {
    const column = Utils.getColumnNum(block.column);
    const contents = block.contents;
    const lines = Utils.convertLines(contents, column);

    return (
      <div
        key={index}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <MediaQuery maxWidth={767}>
          {contents.map((item, i) => {
            return (
              <div key={i} style={{ display: "flex", gap: "10px" }}>
                <DetailItem
                  index={index + i}
                  item={item}
                  pictureAll={pictureAll}
                  isLast={isLast && contents.length - 1 === i}
                />
              </div>
            );
          })}
        </MediaQuery>
        <MediaQuery minWidth={768}>
          {lines.map((line, i) => {
            return (
              <div key={i} style={{ display: "flex", gap: "10px" }}>
                {line.map((item, index2) => {
                  return (
                    <DetailItem
                      index={index + i + index2}
                      key={index2}
                      item={item}
                      pictureAll={pictureAll}
                      isLast={isLast && lines.length - 1 === i}
                    />
                  );
                })}
              </div>
            );
          })}
        </MediaQuery>
      </div>
    );
  };

  return (
    <Layout title={title} description={description} ogImage={ogImage}>
      <main
        css={css`
          padding: 0px 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 88px;
          @media (min-width: 767px) {
            margin-top: 147px;
          }
          @media (min-width: 1299px) {
            margin-top: 159px;
          }
        `}
      >
        {blocks.map((block, index) => {
          return createBlock(block, index, blocks.length - 1 === index);
        })}
      </main>
    </Layout>
  );
};

export default WorkDetailDetail;

export const query = graphql`
  # $slug: "{{ parent_work.parentWork.slug }}"
  query ($slug: String!) {
    wpWorkDetail(slug: { eq: $slug }) {
      title
      title_en {
        titleEn
      }
      date
      blocks {
        block {
          column
          contents {
            type
            picture {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                  fluid {
                    originalImg
                  }
                }
              }
            }
            pictureMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                  fluid {
                    originalImg
                  }
                }
              }
            }
            movieUrl
            movie {
              guid
            }
            movieMobile {
              guid
            }
            context {
              textEn
              textJp
              titleEn
              titleJp
            }
            useLine
          }
        }
      }
      meta_info {
        metaImage {
          guid
          mediaItemUrl
          sizes
          srcSet
        }
        metaDescription {
          en
          ja
        }
      }
    }
  }
`;
